import {useQuery} from '@tanstack/react-query';
import {BackendResponse} from '../../http/backend-response/backend-response';
import {User} from '../user';
import {apiClient} from '../../http/query-client';

export interface FetchUseUserResponse extends BackendResponse {
  user: User;
}

interface Params {
  with: string[];
}

type UserId = number | string | 'me';
const queryKey = (id: UserId, params?: Params) => {
  const key: any[] = ['users', `${id}`];
  if (params) {
    key.push(params);
  }
  return key;
};

export function useUser(id: UserId, params?: Params) {
  return useQuery(queryKey(id, params), () => fetchUser(id, params));
}
export function useWorkspace() {
  return fetchWorkspace();
}
export function useBiolink(params?: Params) {
  return fetchBiolink(params);
}
export function useWorkspaceId(params?: Params) {
  return fetchWorkspaceId(params);
}

function fetchUser(id: UserId, params?: Params): Promise<FetchUseUserResponse> {
  return apiClient.get(`users/${id}`, {params}).then(response => response.data);
}
function fetchWorkspace(): Promise<any> {
  return apiClient.get(`me/workspaces`).then(response => response.data);
}
function fetchBiolink(params?: Params): Promise<any> {
  return apiClient.get(`biolink`, {params}).then(response => response.data.pagination.data);
}

function fetchWorkspaceId(params?: Params): Promise<any> {
  // ?workspaceId=2
  return apiClient.get(`me/workspaces`, {params}).then(response => response.data.workspaces);
}




