import React, {cloneElement, Fragment, useEffect, useState} from 'react';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {useBiolink, useUser, useWorkspace, useWorkspaceId} from '../use-user';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import {Trans} from '@common/i18n/trans';
import {StaticPageTitle} from '@common/seo/static-page-title';
import {useNavigate} from 'react-router-dom';

type T = {[key: string]: string}[];

export function WorkspaceSettingsPage() {
  const {data, isLoading} = useUser('me', {
    with: [''],
  });
  console.log('data.user: ');
  console.log(data);
  const navigate = useNavigate();
  const [workspaces, setWorkspaces] = useState<T | null>(null);
  const [biolinks, setBiolinks] = useState<T | null>(null);

  useEffect(() => {
    async function fetchData() {
      if (data && data.user) {
        const workspace = await useWorkspace();
        const fetchedWorkspaces = workspace.workspaces;
        setWorkspaces(fetchedWorkspaces);

        // @ts-ignore-next-line
        const fetchedBiolink = await useBiolink({
          // perPage: 15,
          // userId: data.user.id,
          // withCount: 'links',
          with: ['user', 'domain'],
          // workspaceId: 0,
        });
        setBiolinks(fetchedBiolink);
      }
    }

    fetchData();
  }, [data]); // Передайте пустий масив для того, щоб useEffect викликалася лише один раз, після монтування компонента

  const handleButtonClick = async (workspaceId: number) => {
    document.cookie = `activeWorkspaceId=${workspaceId}; path=/`;
    console.log(workspaceId);
    const fetchedWorkspaceId =
      await useWorkspaceId(/*{workspaceId: workspaceId}*/);
    console.log(fetchedWorkspaceId);
    if (
      fetchedWorkspaceId &&
      fetchedWorkspaceId[0] &&
      fetchedWorkspaceId.length === 1
    ) {
      console.log('handleButtonClick');
      console.log(fetchedWorkspaceId.length);
      navigate(`/dashboard/biolinks/${fetchedWorkspaceId[0].id}/edit/content`);
      return null; // Or any other UI you want to render when redirecting
    } else {
      console.log('else');
      navigate('/dashboard/biolinks');
    }
  };

  if (workspaces && workspaces.length === 0) {
    if (biolinks && biolinks[0] && biolinks.length === 1) {
      navigate(`/dashboard/biolinks/${biolinks[0].id}/edit/content`);
      return null; // Or any other UI you want to render when redirecting
    } else {
      navigate('/dashboard/biolinks');
    }
    return null; // Or any other UI you want to render when redirecting
  }

  if (biolinks && biolinks.length === 0) {
    console.log('Create biolink!');
    // navigate(`/dashboard/biolinks/${biolink[0].id}/edit/content`);
    // return null; // Or any other UI you want to render when redirecting
  }

  return (
    <div className="min-h-screen bg-alt">
      <StaticPageTitle>
        <Trans message="Personal workspace" />
      </StaticPageTitle>
      <Navbar menuPosition="account-settings-page" />
      <div>
        <div className="container mx-auto my-24 px-24">
          <h1 className="text-3xl">
            <Trans message="Personal workspace" />
          </h1>
          <div className="mb-40 text-base text-muted">
            <Trans message="View and update your account details, profile and more." />
          </div>
        </div>

        <div className="flex items-start gap-24">
          {isLoading || !data ? (
            <ProgressCircle
              className="my-80"
              aria-label="Loading user.."
              isIndeterminate
            />
          ) : (
            <div className="container mx-auto my-24 px-24">
              <main className="flex-auto">
                <div
                  style={{margin: '5px'}}
                  className="pointer-events-auto mx-auto flex max-h-dialog min-w-320 max-w-dialog flex-col overflow-hidden rounded border bg-paper shadow-2xl outline-none"
                >
                  <div className="flex cursor-pointer items-center gap-12 rounded-lg p-10 text-left hover:bg-hover">
                    <div className="flex-auto overflow-y-auto overflow-x-hidden overscroll-contain p-10 text-sm">
                      <div className="text-left">
                        <button
                          className="text-sm font-semibold"
                          onClick={() => handleButtonClick(0)}
                        >
                          Default
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {workspaces &&
                  workspaces.map(workspace => (
                    <div
                      key={workspace.id}
                      style={{margin: '5px'}}
                      className="pointer-events-auto mx-auto flex max-h-dialog min-w-320 max-w-dialog flex-col overflow-hidden rounded border bg-paper shadow-2xl outline-none"
                    >
                      <div className="flex cursor-pointer items-center gap-12 rounded-lg p-10 text-left hover:bg-hover">
                        <div className="flex-auto overflow-y-auto overflow-x-hidden overscroll-contain p-10 text-sm">
                          <div className="text-left">
                            <button
                              className="text-sm font-semibold"
                              onClick={() =>
                                handleButtonClick(Number(workspace.id))
                              }
                            >
                              {workspace.name}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </main>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
